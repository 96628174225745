<template>
  <Layout>
    <div class="app-container RuleEdit">
      <el-tabs tab-position="left">
        <el-tab-pane
          v-for="item in dataList"
          :key="item.index"
          :label="`第${item.index}次开宝箱`"
        >
          <RuleEditForm :form="item" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </Layout>
</template>

<script>
import RuleEditForm from '@/views/TreasureBox/components/RuleEditForm'
import { getBoxRuleOfActivity } from '@/api/TreasureBox'

export default {
  name: 'RuleEdit',
  components: { RuleEditForm },
  data () {
    return {
      dataList: []
    }
  },
  created () {
    this.queryDataList()
  },
  methods: {
    queryDataList () {
      getBoxRuleOfActivity({})
        .then(res => {
          if (res.code === 200) {
            const list = res.data.list || []
            this.dataList = Object.keys(list)
              // .sort((a, b) => (a - b))
              .map(index => {
                return {
                  index: index,
                  ...list[index]
                }
              })
          }
        })
    }
  }
}
</script>

<style lang="less">
  .RuleEdit{
    width:100%;
    height:100%;

    /*加上display和display-direction样式属性就可以解决页面卡死问题*/
    display:flex;
    flex-direction:column;
  }
</style>
