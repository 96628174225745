<template>
  <div class="RuleEditForm">
    <div class="header">
      <el-button
        :loading="loading"
        type="primary"
        @click="handleSaveOrToggle"
      >
        {{ isEdit ? '保存':'编辑' }}
      </el-button>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
    >
      <el-table :data="form.list">
        <el-table-column
          label="奖池商品"
          min-width="100px"
        >
          <template v-slot="{row}">
            <el-form-item v-if="isEdit">
              <el-select
                v-model="row.prizeType"
              >
                <el-option
                  v-for="item in prizeTypeOptions"
                  :key="item.value"
                  :value="item.value"
                  :label="item.name"
                />
              </el-select>
            </el-form-item>
            <span v-else>{{ prizeTypeMaps[row.prizeType].name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="数量"
          min-width="180px"
        >
          <template v-slot="{row}">
            <el-form-item v-if="isEdit">
              <el-input-number
                v-model="row.prizeCount"
                :min="0"
                style="width: 160px"
              />
            </el-form-item>
            <span v-else>{{ row.prizeCount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="爆率"
          min-width="200px"
        >
          <template v-slot="{row}">
            <el-form-item
              v-if="isEdit"
              prop="list"
            >
              <el-input-number
                v-model="row.prizePro"
                :min="0"
                :max="100"
                style="width: 160px"
              /> %
            </el-form-item>
            <span v-else>{{ row.prizePro }} %</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isEdit"
          label="操作"
        >
          <template v-slot="{row, $index}">
            <el-button
              type="text"
              @click="handleDelete(row, $index)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button
      v-if="isEdit"
      type="primary"
      class="add-button"
      @click="handleAddRow"
    >
      <i class="el-icon-plus" />
      添加奖池商品
    </el-button>
  </div>
</template>

<script>
import { updateBoxRuleOfActivity } from '@/api/TreasureBox'

const translateList2Map = (list) => {
  return list.reduce((map, item) => {
    map[item.value] = item
    return map
  }, {})
}
const prizeTypeOptions = [
  { name: '金币', value: 1 },
  { name: 'VIP', value: 2 }
]
const prizeTypeMaps = translateList2Map(prizeTypeOptions)
export default {
  name: 'RuleEditForm',
  props: {
    form: {
      type: Object,
      default: () => ({ list: [] })
    }
  },
  data () {
    const validator = (rule, value, callback) => {
      const total = value.reduce((sum, item) => {
        sum += item.prizePro
        return sum
      }, 0)
      if (total === 100) {
        callback()
      } else {
        callback(new Error('概率之和必须等于100'))
      }
    }
    return {
      prizeTypeOptions,
      prizeTypeMaps,
      isEdit: false,
      rules: {
        list: [
          { validator: validator, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  watch: {
    'form.list': {
      deep: true,
      handler (value) {
        const total = value.reduce((sum, item) => {
          sum += item.prizePro
          return sum
        }, 0)
        if (total === 100) {
          this.$refs.form.clearValidate()
        } else {
          this.$refs.form.validate()
        }
      }
    }
  },
  methods: {
    handleSaveOrToggle () {
      if (!this.isEdit) {
        this.isEdit = true
        return true
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateBoxRuleOfActivity({
            groupId: [this.form.index],
            list: this.form.list
          })
            .then(res => {
              if (res.code === 200) {
                this.isEdit = false
                this.$message.success('已更新')
              } else {
                this.$message.error(res.data)
              }
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          this.$message.error('概率之和必须等于100')
        }
      })
    },
    handleDelete (row, index) {
      this.form.list.splice(index, 1)
    },
    handleAddRow () {
      this.form.list.push({
        prizeType: 1,
        prizeCount: 0,
        prizePro: 0
      })
    }
  }
}
</script>

<style lang="less">
  .RuleEditForm{
    width: 100%;
    .el-form-item{
      margin-bottom: 0;
    }
    .add-button{
      width: 100%;
      margin-top: 30px;
    }
  }
</style>
